<template>
<div>
    <van-nav-bar
      title="标题"
      left-text="返回"
      left-arrow
      @click-left="goBack"/>
    <div>
  <div id="app_div_bank" style="padding: 30px 10px 10px 10px;">
      <ul class="box" style="top: 10px; z-index: 2;">
        <li class="" v-for="(item,index) in cardList" :key="index" >
          <div class="itemMain"  :style="{background:getCardBackColor(item.bankType)}">
            <div class="top clearfix">
              <div class="bankLogo"><img :src="getCardImg(item.bankType)" /></div>
              <div class="bankInfo">
                <p>{{$enum.bankNameEnum[item.bankType]}}</p>
                <p>{{$enum.cardTypeEnum[item.cardType]}}</p>
              </div>
            </div>
            <div class="bottom clearfix"><p>{{item.cardNo}}</p></div>
            <div class="cancel_btn" @click="cancelBind(item.id)"><span>取消绑定</span></div>
          </div>
        </li>
      </ul>
      <div class="add_card" v-if="isShowAdd" @click="$router.push('/bank/add')">
        <van-icon name="plus" /><label for="">添加银行卡</label>
      </div>
      <div class="add_card" v-else >
        <van-icon name="plus" /><label for="">请进行企业认证</label>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import { Button, Cell, CellGroup, Field, Form,Icon,NavBar  } from "vant";
import {allinpayUser} from '../../store/allinpay'
import {getBankCard,removeBind} from '../../api/common'
export default {
  components: {
    [Button.name]: Button,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Field.name]: Field,
    [Form.name]: Form,
    [Icon.name]: Icon,
    [NavBar.name]: NavBar,
  },
  data() {
    return {
      from: {
        name: "",
        authCode: "",
      },
      cardList:[],
      isShowAdd:true
    };
  },
  methods: {
    cancelBind(id){
      debugger
      
      this.$dialog.confirm({
        title: '提示',
        message: '确定解除绑定此银行卡？',
      })
      .then(() => {
        var u = allinpayUser.get();
        var body = {
          CardId:id,
          UserId:u.userId,
          userSource:u.userSource
        };
        removeBind(body).then(res=>{
          this.$toast({type:res.status?'success':'fail',message:res.message})
          var u = allinpayUser.get();
          res.status&&(this.fetchData(u))
        })
      })
      .catch(() => {
        // on cancel
      });
      
    },
    getCardImg(bankType){
      switch(bankType){
        case 1:return require('@/assets/image/gongshang.svg');
        case 2:return require('@/assets/image/nonghang.svg');
        case 4:return require('@/assets/image/jianhang.svg');
        default:return require('@/assets/image/gongshang.svg');
      }
    },
    getCardBackColor(bankType){
      switch(bankType){
        case 1:return 'rgb(254, 96, 99)';
        case 2:return 'rgb(50, 151, 127)';
        case 3:return 'rgb(57, 115, 196)';
        default:return 'rgb(254, 96, 99)';
      }
    },
    onSubmit(values) {
      console.log("submit", values);
    },
    goBack(){
        this.$router.go(-1);
    },
    getTypeByBankName(name){
      for(var i in this.$enum.bankNameEnum){
          if(this.$enum.bankNameEnum[i]==name){
            return parseInt(i);
          }
      }
    },
    fetchData(u){
      this.cardList = [];
      getBankCard({userSource:u.userSource}).then(res=>{
      console.log(res);
      res.forEach(e => {
        this.cardList.push({
          bankType:this.getTypeByBankName(e.bankName),
          cardType:e.cardType,
          cardNo:e.cardNo,
          id:e.id
        })
      });
      console.log(this.cardList);
    })
    }
  },
  destroyed(){
    document.getElementsByTagName('body')[0].style.backgroundColor='#fff';
  },
  created(){
    var u = allinpayUser.get();
    if(u){
      this.isShowAdd = u.memberType == 3;
    }
    this.fetchData(u);
    document.getElementsByTagName('body')[0].style.backgroundColor='#e2e2e2';
  }
};
</script>
<style scoped>
.add_card{
  margin-top: 20px;
  padding: 10px;
  margin-bottom:100px;
  height: 80px;
  line-height: 60px;
  text-align: center;
  /* width: 100%; */
  /* border: 1px solid red; */
  border-radius: 10px;
  background-color: #fff;
}
.bankInfo p:first-child {
    font-size: 16px;
    color: #fff;
}
.bankInfo p {
    font-size: 12px;
    color: #ffffff9c;
}
.box {
    position: relative;
    top: 20px;
    transition: all linear .2s;
}
.box li {
    font-size: 14px;
    width: 100%;
    /* position: absolute; */
    margin-bottom: 10px;
    z-index: 0;
    transition: all linear .2s;
    /* background-image: url('../assets/image/nonghang.svg');
    background-repeat: no-repeat;
    background-size: 100%; */
}
.bankLogo {
    border-radius: 50%;
    background: #fff;
    text-align: center;
    width: 30px;
    height: 30px;
    float: left;
}
.box li .itemMain {
    border-radius: 5px;
    padding: 15px;
}
.bankInfo {
    float: left;
    margin-left: 10px;
}
.bankNum {
    float: right;
    margin-top: 10px;
    color: #fff;
}
.bottom {
    margin-top: 5px;
    text-align: center;
    font-size: 24px;
    color: white;
}
.cancel_btn{
  position:absolute;
  right: 10px;
  bottom: 10px;
  color: white;
  cursor: pointer;
}
</style>